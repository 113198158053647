<!--<app-header [inheritedLanguage]="this.language"></app-header>-->
<app-design-view-header-background [diplomaDesign]="this.diplomaDesign"></app-design-view-header-background>
<ng-container>
  <app-design-view-header [smallResolution]="true"> </app-design-view-header>
</ng-container>
<main class="container">
  <div class="row flex-lg-row wrapper">
    <div class="content-box">
      <div class="content-box-header">
        <div class="box-header">
          <h1>{{ 'download other formats' | translate }}</h1>
        </div>
        <p>
          {{
            'You can always download a PDF-version of diplomas, but as a supplement the issuer supports alternative format(s) listed below:'
              | translate
          }}
        </p>
      </div>

      <div class="content-box-blockcerts" *ngIf="this.diplomaData.diploma.has_blockcert">
        <a
          data-toggle="collapse"
          href="#blockcertsInfo"
          role="button"
          aria-expanded="false"
          aria-controls="blockcertsInfo"
        >
          <div class="box-header d-flex align-items-center justify-content-between">
            <h4>blockcert</h4>
            <i class="fa fa-minus"></i>
            <i class="fa fa-plus"></i>
          </div>
        </a>
        <div class="collapse" id="blockcertsInfo">
          <p>
            {{
              'Blockcerts is an open standard for creating, issuing,
            viewing, and verifying blockchain-based certificates. These
            digital records are registered on a blockchain, cryptographically signed,
            tamper-proof, and shareable. Learn more about Blockcerts on: https://blockcerts.org' | translate
            }}
          </p>

          <div class="download-button" (click)="downloadBlockcert()">
            {{ 'Download Blockcert' | translate }}
          </div>
          <div class="download-button" (click)="copyLink()">
            {{ 'Copy URL' | translate }}
          </div>
        </div>
      </div>
      <div class="content-box-blockcerts" *ngIf="this.diplomaData.diploma.has_badge">
        <a
          data-toggle="collapse"
          href="#openBadgeInfo"
          role="button"
          aria-expanded="false"
          aria-controls="openBadgeInfo"
        >
          <div class="box-header d-flex align-items-center justify-content-between">
            <h4>Open Badge</h4>
            <i class="fa fa-minus"></i>
            <i class="fa fa-plus"></i>
          </div>
        </a>
        <div class="collapse" id="openBadgeInfo">
          <p>
            {{
              'Open Badges is an open standard for digital badges where your identity and information of your skills and
            achievements are embedded in an image file as metadata. Learn more about Open Badges on: https://openbadges.org'
                | translate
            }}
          </p>

          <div class="download-button badge" (click)="downloadOpenBadge()">
            {{ 'Download Open Badge' | translate }}
          </div>
        </div>
      </div>

      <div class="content-box-blockcerts" *ngIf="this.diplomaData.diploma.can_request_ebsi">
        <a
          data-toggle="collapse"
          href="#ebsiCredential"
          role="button"
          aria-expanded="false"
          aria-controls="ebsiCredential"
        >
          <div class="box-header d-flex align-items-center justify-content-between">
            <h4>{{ 'Request EBSI credential' | translate }}</h4>

            <i class="fa fa-minus"></i>
            <i class="fa fa-plus"></i>
          </div>
        </a>

        <div class="collapse" id="ebsiCredential" style="padding: 20px 35px">
          <p style="padding: 0">
            {{
              "The EBSI credential component allows you to request a digital credential that is stored on a blockchain. You\'ll need to enter your Decentralized Identifier (DID) to request the credential. After you make the request, a QR code will be displayed."
                | translate
            }}
          </p>

          <form (ngSubmit)="requestEBSICredential()">
            <mat-form-field style="width: 100%">
              <mat-label for="userDid">{{ 'Enter your DID' | translate }}</mat-label>

              <input
                matInput
                type="text"
                name="userDid"
                placeholder="{{ 'Enter your DID' | translate }}"
                [(ngModel)]="userDid"
                [formControl]="ebsiFormControl"
              />

              <mat-error *ngIf="ebsiFormControl.hasError('pattern')">
                {{ 'Invalid DID format' | translate }}
              </mat-error>
              <mat-error *ngIf="ebsiFormControl.hasError('required')">
                {{ 'DID is required' | translate }}
              </mat-error>
            </mat-form-field>

            <button style="margin-top: 10px" mat-raised-button type="submit" class="submit">
              {{ 'Request credential' | translate }}
            </button>
          </form>

          <div *ngIf="this.qrCodeImg" style="display: flex; justify-content: center">
            <img [src]="this.qrCodeImg" alt="{{ 'EBSI Credential QR Code' | translate }}" />
          </div>
        </div>
      </div>

      <div class="content-box-europass" *ngIf="false">
        <a data-toggle="collapse" href="#europassInfo" role="button" aria-expanded="false" aria-controls="europassInfo">
          <div class="box-header d-flex align-items-center justify-content-between">
            <h4>europass</h4>
            <i class="fa fa-minus"></i>
            <i class="fa fa-plus"></i>
          </div>
        </a>
        <div class="collapse" id="europassInfo">
          <p>some-info</p>
        </div>
      </div>
    </div>
    <div class="header-wrapper col-xl-2 col-lg-3 col-md-12 col-sm-12">
      <app-design-view-header [otherFormats]="true"></app-design-view-header>
    </div>
  </div>
  <app-design-view-footer class="hide-below-1000"></app-design-view-footer>
</main>
