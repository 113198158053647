import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';

const EMAIL_REGEX = /^([\w\-\.+]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,9}))$/;
const LEARNER_DID_REGEX = /^did:key:[a-zA-Z0-9.-]+$/;

@Injectable()
export class FormValidationService {
  public emailValidation() {
    return new FormControl(
      '',
      [
        Validators.required,
        Validators.pattern(EMAIL_REGEX),
      ],
    );
  }

  public passwordValidation() {
    return new FormControl(
      '',
      [
        Validators.required,
      ],
    );
  }

  public inputValidation() {
    return new FormControl(
      '',
      [
        Validators.required,
      ],
    );
  }

  public validLearnerDid() {
    return new FormControl(
      '',
      [
        Validators.required,
        Validators.pattern(LEARNER_DID_REGEX),
      ],
    );
  }
}
