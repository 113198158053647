import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";

import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";

import {
  ClaimDiploma,
  DiplomaData,
  DiplomaDesign,
  DiplomaPresentationData,
  DiplomaAccessLevel,
} from "../../models/diploma";
import { Share } from "../../models/share";
import { Status } from "../../models/status";

import { environment } from "../../../../environments/environment";
import { BehaviorSubject, forkJoin, of, Subject, Subscription, throwError } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { DiplomasafeResponse } from "app/core/models/Http/DiplomasafeResponse";
import { delay, mergeMap, retryWhen, shareReplay, take, takeUntil } from "rxjs/operators";
import { DiplomaFieldService } from "./diploma-field.service";
import { OrganizationService } from "../organization/organization.service";
import { UserService } from "../user/user.service";
import { indicate } from "app/core/operators/indicate";
import { HttpUtilService } from "../util/http-util.service";
import { CheckClaimGoalSelection } from "../../models/goals/check-claim-goal-selection";
import { DiplomaMetaDataInterface } from '../../types/interfaces/diploma/diploma-meta-data.interface';

const apiUrl = environment.API_START_URL;
const getDiplomaForClaim = (diplomaHash: String, lang: String, id: String, session: String) => `${apiUrl}api/get-claim-diploma/${lang}/${diplomaHash}/${id}/${session}`;
const diplomaPresentationUrl = (diplomaHash: string, lang: string) => `${apiUrl}api/diploma-presentation/${lang}/${diplomaHash}`;
const linkedinProfileBaseUrl = (): string => "https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&";
const DIPLOMA_ACCESS_LEVEL = (langCode: string, uHash: string): string => `${apiUrl}api/diploma-access-level/${langCode}/${uHash}`;
const CLAIM_GOAL_SELECTION = (diplomaHash: string): string => `${apiUrl}api/diploma/${diplomaHash}/claim-goal-selection`;

@Injectable({
  providedIn: "root",
})
export class DiplomaService {
  private showDiplomaForAdminUrl = `${apiUrl}api/showDiplomaForAdmin`
  private apiGetDesignViewForAdmin = `${apiUrl}api/designViewForAdmin`
  private apiShowDiplomaUrl = apiUrl + "api/showDiploma";
  private apiShareView = apiUrl + "api/share-view/";
  private apiGetMetaData = apiUrl + "api/metaData/";
  private apiGetViewType = apiUrl + "api/view-type/";
  private apishareDataUrl = apiUrl + "api/socialShareDataPost";
  private apiDiplomaPDFUrl = apiUrl + "api/generateDiplomaCertificateinPDF";
  private apiGetDesignView = apiUrl + "api/designView/";
  private apiGetUserDataUrl = apiUrl + "api/getUserDetails";
  private apiUserDiplomasUrl = apiUrl + "api/getUserDiplomas";
  private apiDiplomaStatusUrl = apiUrl + "api/diplomaStatus";
  private apigetDiplomaFullUrl = apiUrl + "api/apigetDiplomaFullUrl";
  private apiValidateIdentifier = apiUrl + "api/validateIdentifier";
  private apiupdateDiplomaStatusUrl = apiUrl + "api/updateDiplomaStatus";
  private deleteDiplomaByUserandDiplomaHash = apiUrl + "api/deleteDiplomaByUserandDiplomaHash";
  private apiUpdateDiplomaAutoDeleteExpiredStatusUrl = apiUrl + "api/updateDiplomaAutoDeleteExpiredStatus";
  private getDiplomaChangeRequestUrl = apiUrl + "api/getDiplomaWithPendingChangeRequest/";
  private apiGenerateCredentialOffer: string = `${apiUrl}api/request-ebsi-vc-issuance`;

  private subscriptions: Subscription[];
  private previewType: BehaviorSubject<string>;
  private specialFields: BehaviorSubject<string[]>;
  private designViewType: Subject<DiplomaDesign>;
  private diplomaSubject: Subject<ClaimDiploma>;
  private diplomaLoadingSubject: Subject<boolean>;
  private shareViewLoadingSubject: Subject<boolean>;
  private designViewSubject: Subject<DiplomaPresentationData>;
  private params = this.activatedRoute.snapshot.params;
  private supportedLanguages = [
    "en-US", "da-DK", "pt-PT", "nl-NL", "it-IT", "fr-FR", "ru-RU", "lt-LT",
    "pl-PL", "is-IS", "es-ES", "de-DE", "tr-TR", "nb-NO", "sv-SE", "sr-RS"
  ];

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private httpUtilService: HttpUtilService,
    private diplomaFieldService: DiplomaFieldService,
    private organizationService: OrganizationService
  ) {
    this.designViewSubject = new Subject<DiplomaPresentationData>();
    this.subscriptions = [];
    this.previewType = new BehaviorSubject<string>(undefined);
    this.specialFields = new BehaviorSubject<string[]>([]);
    this.designViewType = new Subject<DiplomaDesign>();
    this.diplomaSubject = new Subject<ClaimDiploma>();
    this.diplomaLoadingSubject = new Subject<boolean>();
    this.shareViewLoadingSubject = new Subject<boolean>();
  }

  public getUserData(accessToken): Observable<any> {
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });
    return this.httpClient
      .get(this.apiGetUserDataUrl, { headers: header })
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((err) => this.handleError(err));
  }

  public getDiplomaData(uh, nicename, lang, session, accessToken, special = false, type, adminViewHash: string = '', token: string|null = null): Observable<DiplomaData> {
    const uhash = accessToken ? 1 : 0;
    const url = this.generateUrl(lang, uh, nicename, session, uhash, accessToken, special, type, adminViewHash, token);

    if (accessToken) {
      const header = new HttpHeaders({
        Authorization: "Bearer " + accessToken,
      });
      return this.httpClient
        .get(url, { headers: header })
        .pipe(
          retryWhen((errors) => {
            // Used for special share views. Retry until the image creation job fails or is done, or the max number of retries is met.
            let retries = 0;
            return errors.pipe(
              mergeMap((error) => {
                if (error.status === 404 && error.share_view_loading === true && retries++ < 10) {
                  this.shareViewLoadingSubject.next(true);
                  return of(error).pipe(delay(2000));
                }
                return throwError(error);
              })
            );
          }),
          shareReplay()
        )
        .map((res: HttpResponse<any>) => this.extractData(res))
        .catch((res: HttpResponse<any>) => this.handleError(res));
    } else {
      return this.httpClient
        .get(url)
        .pipe(
          // Used for special share views. Retry until the image creation job fails or is done, or the max number of retries is met.
          retryWhen((errors) => {
            let retries = 0;
            return errors.pipe(
              mergeMap((error) => {
                if (error.status === 404 && error.share_view_loading === true && retries++ < 10) {
                  this.shareViewLoadingSubject.next(true);
                  return of(error).pipe(delay(2000));
                }
                return throwError(error);
              })
            );
          }),
          shareReplay()
        )
        .map((res: HttpResponse<any>) => this.extractData(res))
        .catch((res: HttpResponse<any>) => this.handleError(res));
    }
  }

  public validateIdentifier(uh, nicename, lang, accessToken, identifierValue): Observable<any[]> {
    if (accessToken) {
      const header = new HttpHeaders({
        Authorization: "Bearer " + accessToken,
      });
      return this.httpClient
        .get(this.apiValidateIdentifier + "/" + identifierValue + "/" + lang + "/" + uh + "/" + nicename, {
          headers: header,
        })
        .map((res: HttpResponse<any>) => this.extractData(res))
        .catch((res: HttpResponse<any>) => this.handleError(res));
    } else {
      return this.httpClient
        .get(this.apiValidateIdentifier + "/" + identifierValue + "/" + lang + "/" + uh + "/" + nicename)
        .map((res: HttpResponse<any>) => this.extractData(res))
        .catch((res: HttpResponse<any>) => this.handleError(res));
    }
  }

  public getDiplomaStatus(lang, uh, accessToken): Observable<any[]> {
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });

    return this.httpClient
      .get(this.apiDiplomaStatusUrl + "/" + lang + "/" + uh, {
        headers: header,
      })
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((res: HttpResponse<any>) => this.handleError(res));
  }

  public deleteDiploma(accessToken: string, uHash: string) {
    const errorMessage = "Could not delete diploma";
    const subscription = this.httpClient
      .post<DiplomasafeResponse>(
        this.deleteDiplomaByUserandDiplomaHash,
        { uhash: uHash },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      )
      .subscribe(
        (res) => {
          this.subscriptions.push(subscription);
          if (res.status === "success") {
            this.router.navigate([this.params.lang, "dashboard"]);
          } else {
            this.navigateToErrorPage(errorMessage);
          }
        },
        (err) => {
          this.navigateToErrorPage(errorMessage);
        }
      );
  }

  public getUserDiplomas(accessToken, lang): Observable<any> {
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });

    return this.httpClient
      .get(this.apiUserDiplomasUrl + "/" + lang, { headers: header })
      .map(this.extractDiplomaData)
      .catch((res: HttpResponse<any>) => this.handleError(res));
  }

  public generateDiplomaPdf(uhash, id, lang, identifierValue): Observable<any> {
    if (identifierValue) {
      return this.httpClient
        .get(this.apiDiplomaPDFUrl + "/" + uhash + "/" + id + "/" + lang + "/" + identifierValue)
        .map((res: HttpResponse<any>) => this.extractData(res))
        .catch((res: HttpResponse<any>) => this.handleError(res));
    } else {
      return this.httpClient
        .get(this.apiDiplomaPDFUrl + "/" + uhash + "/" + id + "/" + lang)
        .map((res: HttpResponse<any>) => this.extractData(res))
        .catch((res: HttpResponse<any>) => this.handleError(res));
    }
  }

  public getFullURL(baseCode, lang): Observable<any> {
    return this.httpClient
      .get(this.apigetDiplomaFullUrl + "/" + baseCode)
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((res: HttpResponse<any>) => this.handleError(res));
  }

  public shareData(body: any, accessToken): Observable<Share[]> {
    const bodyString = JSON.stringify(body);
    const header = new HttpHeaders({
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    });
    return this.httpClient
      .post(this.apishareDataUrl, body, { headers: header })
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((err) => this.handleError(err));
  }

  public generateCredentialOffer(body: any, accessToken: string): Observable<{ url: string, qr_code: string, message: string }> {
    const header = new HttpHeaders({
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    });

    return this.httpClient
      .post(this.apiGenerateCredentialOffer, body, { headers: header })
      .map((res: HttpResponse<{ url: string, qr_code: string, message: string }>) => this.extractData(res))
      .catch((err) => this.handleError(err));
  }

  public updateDiplomaStatus(body, accessToken): Observable<Status[]> {
    const header = new HttpHeaders({
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    });
    return this.httpClient
      .post(this.apiupdateDiplomaStatusUrl, body, { headers: header })
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((err) => this.handleError(err));
  }

  public updateDiplomaAutoDeleteExpiredStatus(body, accessToken) {
    const header = new HttpHeaders({
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    });
    return this.httpClient.post(this.apiUpdateDiplomaAutoDeleteExpiredStatusUrl, body, { headers: header });
  }

  public getDesignView(lang, uhash, session, accessToken, special, pub, type, adminViewHash: string|null = null, token: string|null = null) {
    const errorMessage = "Could not get diploma";
    const subscritpion = this.httpClient
      .get<DiplomaData>(this.getDesignViewUrl(lang, uhash, session, accessToken, special, pub, type, adminViewHash, token), {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .pipe(
        // Used for special share views. Retry until the image creation job fails or is done, or the max number of retries is met.
        retryWhen((errors) => {
          let retries = 0;
          return errors.pipe(
            mergeMap((error) => {
              if (error.status === 404 && error.share_view_loading === true && retries++ < 10) {
                this.shareViewLoadingSubject.next(true);
                return of(error).pipe(delay(2000));
              }
              return throwError(error);
            })
          );
        }),
        shareReplay()
      )
      .subscribe(
        (data) => {
          if ((!data.diploma.same_user && data.diploma.diploma_status === 2) || data.incorrect) {
            this.router.navigate([this.params.lang + "/not-found"], {
              state: { errorMessage: errorMessage, isDiploma: true },
            });
          } else {
            this.designViewSubject.next(data.diploma);
            this.organizationService.setOrganization(data.organization);
            this.userService.setDiplomaRecipient(data.recipient);
            this.fillSpecialFields(data.diploma.diplomaFieldData);
          }
          this.subscriptions.push(subscritpion);
        },
        (err) => {
          this.navigateToErrorPage(errorMessage, lang);
        }
      );
  }

  public getDiplomaPresentationData(lang, hash, accessToken) {
    const headers = {
      Authorization: "Bearer " + accessToken,
      content: "application/json",
    };
    const diplomaDesignRequest = this.httpClient.get<DiplomaDesign>(this.apiGetViewType + 0 + "/" + hash, {
      headers,
    });
    const diplomaDataRequest = this.httpClient.get<DiplomaData>(diplomaPresentationUrl(hash, lang), {
      headers,
    });

    forkJoin([diplomaDesignRequest, diplomaDataRequest])
      .pipe(indicate(this.diplomaLoadingSubject), takeUntil(this.httpUtilService.getCancelRequestObservable()))
      .subscribe(
        ([design, data]) => {
          this.designViewType.next(design);
          this.userService.setDiplomaRecipient(data.recipient);
          this.designViewSubject.next(data.diploma);
          this.fillSpecialFields(data.diploma.diplomaFieldData);
        },
        (err) => {
          this.navigateToErrorPage("Could not get diploma");
        }
      );
  }

  public checkAccessLevelAndGetDiplomaInfo(
    uHash: string,
    userHash: string | null,
    langCode: string,
    accessToken: string
  ): Observable<DiplomaAccessLevel> {
    return this.httpClient.get<DiplomaAccessLevel>(
      DIPLOMA_ACCESS_LEVEL(langCode, uHash) + (userHash ? "/" + userHash : ""),
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
  }

  public withGoalSelectionPage(diplomaHash: string, accessToken: string): Observable<CheckClaimGoalSelection> {
    return this.httpClient.get<CheckClaimGoalSelection>(CLAIM_GOAL_SELECTION(diplomaHash), {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    });
  }

  public checkDesignView(uhash, accessToken, specialShare) {
    const type = specialShare ? "1" : "0";

    const header = new HttpHeaders({
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    });
    const subscription = this.httpClient
      .get(this.apiGetViewType + type + "/" + uhash, { headers: header })
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((err) => this.handleError(err))
      .subscribe(
        (res) => {
          this.designViewType.next(res);
          this.subscriptions.push(subscription);
        },
        (err) => {
          this.navigateToErrorPage("The diploma not found!");
        }
      );
  }

  public destroySubscription() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public getDesignViewObservable(): Observable<DiplomaPresentationData> {
    return this.designViewSubject.asObservable();
  }

  public copyToClipboard(value, successful, unsuccessful) {
    const textArea = document.createElement("textarea");
    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = "0.5em";
    textArea.style.height = "0.5em";
    textArea.style.padding = "0";
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";
    textArea.value = value;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      const success = document.execCommand("copy");
      const msg = success ? successful : unsuccessful;
      alert(msg);
    } catch (err) {
      alert("Oops, unable to copy");
    }
    document.body.removeChild(textArea);
  }

  private extractData(res) {
    return res || {};
  }

  private extractDiplomaData(res: HttpResponse<any>) {
    return res || {};
  }

  public fillSpecialFields(diplomaFields) {
    this.specialFields.next(
      diplomaFields
        .filter((x) => {
          return x.special || this.diplomaFieldService.tableSpecial(x);
        })
        .map((x) => {
          return x.name;
        })
    );
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse) {
      if (error.status === 401 || error.status === 403) {
        this.userService.clearSessionRedirectToLogin();
      }
      const body = error || "";
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }

  public getSpecialFields() {
    return this.specialFields.asObservable();
  }

  public pushType(type: string) {
    this.previewType.next(type);
  }

  public getPreviewType() {
    return this.previewType.asObservable();
  }

  public getDiplomaDesign() {
    return this.designViewType.asObservable();
  }

  public getDiplomaObservable() {
    return this.diplomaSubject.asObservable();
  }

  public getShareViewLoading() {
    return this.shareViewLoadingSubject.asObservable();
  }

  public getDiplomaLoadingObservable(): Observable<boolean> {
    return this.diplomaLoadingSubject.asObservable();
  }

  public fetchDiploma(diplomaHash: String, lang: String, id: String, accessToken: String, oidc: boolean) {
    const session = localStorage.getItem("browserSession");
    const header = {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    };
    const userHash = accessToken ? "1" : "";
    const subscription = this.httpClient
      .get<ClaimDiploma>(getDiplomaForClaim(diplomaHash, lang, id, session) + `/${userHash}`, {
        headers: header,
      })
      .subscribe(
        (res) => {
          this.subscriptions.push(subscription);
          if (res.diploma_status === 2) {
            this.router.navigate([lang, "diploma", diplomaHash]);
          } else if (res.openid && oidc && res.registered) {
            document.location.href = environment.API_START_URL + "api/openid/" + res.openid;
          } else {
            this.diplomaSubject.next(res);
          }
        },
        (err) => {
          this.navigateToErrorPage("Could not get diploma");
        }
      );
  }

  public getMetaData(lang, uHash): Observable<DiplomaMetaDataInterface> {
    return this.httpClient
      .get(this.apiGetMetaData + lang + "/" + uHash, )
      .map((res: Response) => this.extractData(res))
      .catch((err) => this.handleError(err));
  }

  public linkedinAddToProfile(
    diplomaName: string,
    organizationName: string,
    issuedDate: string,
    expiryDate: string | null,
    linkedinId: string | null = null
  ): string {
    let url = linkedinProfileBaseUrl();
    url += `name=${diplomaName}`;

    url += linkedinId
      ? `&organizationId=${linkedinId}`
      : `&organizationName=${organizationName}`;

    const issueDateObject = new Date(issuedDate);
    url += `&issueYear=${issueDateObject.getFullYear()}&issueMonth=${issueDateObject.getMonth() + 1}`;

    if (expiryDate) {
      const expiryDateObject = new Date(expiryDate);
      url += `&expirationYear=${expiryDateObject.getFullYear()}&expirationMonth=${expiryDateObject.getMonth() + 1}`;
    }

    url += `&certUrl=${environment.APP_URL + this.router.url}`;
    return url;
  }

  public getDiplomaRequestChangeData(uhash: string, changeRequestType: string, accessToken: string) {
    const header = new HttpHeaders({ Authorization: "Bearer " + accessToken });

    return this.httpClient
      .get(this.getDiplomaChangeRequestUrl + uhash + "/" + changeRequestType, { headers: header })
      .map((res: HttpResponse<any>) => this.extractData(res))
      .catch((err) => this.handleError(err));
  }

  private generateUrl(lang, uh, nicename, session, uhash, accessToken, special, type, adminViewHash: string, token: string|null) {
    if (special) {
      return this.apiShareView + lang + "/" + uh + "/0/" + session + "/" + uhash;
    }

    if (adminViewHash) {
      return `${this.showDiplomaForAdminUrl}/${lang}/${uh}/${type}/${session}/${uhash}/${adminViewHash}?token=${token}`;
    }

    if (accessToken) {
      return this.apiShowDiplomaUrl + "/" + lang + "/" + uh + "/" + nicename + "/" + type + "/" + session + "/" + uhash;
    }

    return this.apiShowDiplomaUrl + "/" + lang + "/" + uh + "/" + nicename + "/" + type + "/" + session;
  }

  private getDesignViewUrl(lang, uh, session, accessToken, special, pub, type, adminViewHash: string, token: string|null) {
    const userHash = accessToken ? "1" : "0";

    if (special) {
      return this.apiShareView + lang + "/" + uh + "/1/" + session + "/" + userHash;
    }

    if (adminViewHash) {
      return `${this.apiGetDesignViewForAdmin}/${lang}/${uh}/${session}/${type}/${userHash}/${adminViewHash}?token=${token}`;
    }

    if (accessToken && !pub) {
      return this.apiGetDesignView + lang + "/" + uh + "/" + session + "/" + type + "/" + userHash;
    }

    return this.apiGetDesignView + lang + "/" + uh + "/" + session + "/" + type;
  }

  private navigateToErrorPage(errorMessage?: string, lang?: string) {
    let browserLanguage = lang? lang : navigator.language;
    if (this.supportedLanguages.includes(browserLanguage)) {
      this.router.navigate([browserLanguage + "/not-found"], {
        state: { errorMessage: errorMessage, isDiploma: true },
      });
    } else {
      this.router.navigate([this.params.lang + "/not-found"], {
        state: { errorMessage: errorMessage, isDiploma: true },
      });
    }
  }

  public downloadPdf(url: string): Observable<any> {
    return this.httpClient.get(url, {
      observe: 'response',
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/pdf'")
    });
  }
}
