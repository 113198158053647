import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiplomaService } from '../../../core/services/diploma/diploma.service';
import { Subscription } from 'rxjs';
import { DiplomaData, DiplomaDesign } from '../../../core/models/diploma';
import { BlockcertsService } from '../../../core/services/blockcerts/blockcerts.service';
import { OpenBadgeService } from 'app/core/services/open-badge/open-badge.service';
import { FormValidationService } from 'app/core/services/common-service/fromvalidation.service';

@Component({
  providers: [DiplomaService,FormValidationService],
  selector: 'app-diploma-other-formats',
  templateUrl: './diploma-other-formats.component.html',
  styleUrls: ['./diploma-other-formats.component.css'],
})
export class DiplomaOtherFormatsComponent implements OnInit, OnDestroy {
  public diplomaDesign: DiplomaDesign;
  public diplomaData: DiplomaData;
  private diplomaDesignSubscription: Subscription;
  private params: any = this.activatedRoute.snapshot.params;

  public ebsiFormControl = this.formValidationService.validLearnerDid();
  public userDid: string = null;
  public qrCodeImg: any = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private diplomaService: DiplomaService,
    private blockcertService: BlockcertsService,
    private openBadgeService: OpenBadgeService,
    private formValidationService: FormValidationService,
  ) {
    this.diplomaDesignSubscription = this.diplomaService.getDiplomaDesign().subscribe((diplomaDesign) => {
      this.diplomaDesign = diplomaDesign;
    });
  }

  async ngOnInit() {
    this.diplomaService.checkDesignView(this.params.uh, this.userDetails.accessToken, false);
    await this.loadData();
  }

  public downloadBlockcert() {
    if (!this.diplomaData) {
      return;
    }

    this.blockcertService.downloadCertificate(this.diplomaData.diploma.diploma_id_plain);
  }

  public copyLink() {
    if (!this.diplomaData) {
      return;
    }

    this.diplomaService.copyToClipboard(this.diplomaData.diploma.blockcert_link, 'URL coppied to clipboard', 'Error');
  }

  public requestEBSICredential() {
    if (this.ebsiFormControl.invalid) {
      return;
    }

    this.diplomaService
      .generateCredentialOffer(
        {
          user_did: this.userDid,
          credential_hash: this.diplomaData.diploma.diploma_hash,
        },
        this.userDetails.accessToken,
      )
      .subscribe(
        (response) => {
          if (!response.qr_code) {

          }
          this.qrCodeImg = response.qr_code;

        },
        (error) => {
          console.error(error);
        },
      );
  }

  public downloadOpenBadge() {
    if (!this.diplomaData) {
      return;
    }

    this.openBadgeService.downloadBadge(this.diplomaData.diploma.diploma_hash);
  }

  get userDetails() {
    if (localStorage.getItem('currentUser')) {
      const userDetail = JSON.parse(localStorage.getItem('currentUser'));
      if (userDetail.accessToken) {
        return userDetail;
      }
    }
    return false;
  }

  get language() {
    return this.activatedRoute.snapshot.params.lang;
  }

  ngOnDestroy() {
    this.diplomaDesignSubscription.unsubscribe();
  }

  private async loadData() {
    const session = localStorage.getItem('browserSession');
    const accessToken = this.userDetails.accessToken;
    this.diplomaService.getPreviewType().subscribe((type) => {
      this.diplomaService
        .getDiplomaData(this.params.uh, this.params.nicename, this.params.lang, session, accessToken, false, type)
        .toPromise()
        .then((data) => {
          this.diplomaData = data;
        });
    });
  }
}
